import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getCategory as fetchCategory, getTableDetail, menuPhoto } from '../../../api/Api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const Viewmenu = () => {
    const [data, setData] = useState({});
    const [category, setCategory] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const { id } = useParams();

    const getMenu = async () => {
        try {
            const response = await axios.get(`${getTableDetail}/${id}`);
            if (response.data.status) {
                setData(response.data.data);
                const initialQuantities = {};
                response.data.data.menu.forEach(menu => {
                    initialQuantities[menu.id] = 1;
                });
                setQuantities(initialQuantities);
            }
        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    };

    useEffect(() => {
        getMenu();
    }, [id]);

    const handleQuantityChange = (menuId, increment) => {
        setQuantities(prevQuantities => {
            const updatedQuantities = { ...prevQuantities };
            const newQty = (updatedQuantities[menuId] || 1) + increment;
            updatedQuantities[menuId] = Math.max(newQty, 1);
            return updatedQuantities;
        });
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const bgColor = data.restaurant?.color1 || 'rgba(0,0,255,0.3)';
    const textColor = data.restaurant?.color2 || 'white';
    const currentTimestamp = data.currentTimeStamp;
    const validUpto = data.table?.validupto;


    const filteredMenu = searchTerm.length > 2
        ? data.menu?.filter(menu => menu.title.toLowerCase().contains(searchTerm.toLowerCase()))
        : data.menu;

    return (
        <div style={{ backgroundColor: bgColor, width: "100%", height: "100vh" }}>
            <div className="container">
                <div className="row pt-5">
                    <div className="col-md-12">
                        <h1 style={{ color: textColor }} className="text-center">
                            <b>{data.restaurant?.name}</b>
                        </h1>
                    </div>
                </div>
                {validUpto > currentTimestamp ? (
                    <div className="row">

                        <div className="col py-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="container">
                            <div className="row">
                                {filteredMenu?.map((menu) => (
                                    <div className="col-md-4" key={menu.id}>
                                        <div className="card">
                                            <img
                                                src={`${menuPhoto}/${menu?.photo}`}
                                                style={{ height: "250px", objectFit: "cover", aspectRatio: "2/3" }}
                                                alt={menu.title}
                                            />
                                            <div className="card-body">
                                                <h5 className="card-title" style={{ color: textColor }}>{menu.title}</h5>
                                                <p className="card-text">Price: ₹{menu.price}</p>
                                                <p className="card-text">Status: {menu.status}</p>
                                                <form>
                                                    <input type="hidden" name="menuId" value={menu.id} />
                                                    <div className="input-group mb-3 w-50">
                                                        <div className="input-group-prepend">
                                                            <button
                                                                className="btn btn-outline-secondary"
                                                                type="button"
                                                                onClick={() => handleQuantityChange(menu.id, 1)}
                                                            >+</button>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control text-center w-25"
                                                            name="qty"
                                                            value={quantities[menu.id] || 1}
                                                            readOnly
                                                        />
                                                        <div className="input-group-append">
                                                            <button
                                                                className="btn btn-outline-secondary"
                                                                type="button"
                                                                onClick={() => handleQuantityChange(menu.id, -1)}
                                                            >-</button>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="btn btn-primary" style={{ backgroundColor: bgColor, color: textColor }}>Order Now</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-md-12">
                            <h1 style={{ color: textColor }} className="text-center">
                                <b>Table is not available</b>
                            </h1>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Viewmenu;
