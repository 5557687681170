import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { baseUrl, baseUrl2 } from '../../../api/Api';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Print = () => {
    const restaurantName = localStorage.getItem("name");
    const { tableNumber } = useParams();
    const name = 'view-menu';
    const qrCodeUrl = `${baseUrl2}${name}/${tableNumber}`;

    const generatePDF = () => {
        const input = document.getElementById('pdf-content');
        html2canvas(input, { useCORS: true }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgWidth = 190;
            const pageHeight = pdf.internal.pageSize.height;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            let position = 0;

            pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('order.pdf');
        });
    };

    return (
        <>
            <style>{`
            * {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
            }

            body {
                font-family: Arial, sans-serif;
                background-color: #f8f8f8;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
            }

            .card1 {
                width: 320px;
                background-color: white;
                border: 1px solid #ddd;
                border-radius: 8px;
                text-align: center;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                overflow: hidden;
                padding: 20px; // Added padding for better layout
            }

            .header1 {
                background-color: #f3961c;
                padding: 20px;
                height: 100px;
                position: relative;
            }

            .header1 p {
                font-size: 22px;
                color: white;
                margin: 0;
                font-weight: bold;
                text-transform: uppercase;
            }

            .logo {
                background-color: white;
                border-radius: 50%;
                width: 120px;
                height: 120px;
                margin: 10px auto;
                position: relative;
                top: -60px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .logo img {
                width: 100px;
            }

            h2 {
                font-size: 20px;
                color: #333;
                margin-top: -75px;
                font-weight: bold;
            }

            .qr-code {
                margin-top: 10px;
                background-color: white;
                padding: 10px;
            }

            .table-number {
                font-size: 18px;
                color: #333;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .btn {
                margin-top: 20px;
                padding: 10px 15px;
                font-size: 16px;
                background-color: #007bff;
                color: white;
                border: none;
                border-radius: 5px;
                cursor: pointer;
            }

            .btn:hover {
                background-color: #0056b3; // Darker shade on hover
            }
            `}</style>

            <div id="pdf-content" className="card1">
                <div className="header1">
                    <p>ORDER HERE</p>
                </div>
                <div className="logo">
                    <img src="https://www.flipcodesolutions.com/img/logo.png" alt="Kard's Wirtshaus Logo" />
                </div>
                <h2>{restaurantName}</h2>
                <div className="qr-code">
                    <QRCode value={qrCodeUrl} size={150} />
                </div>
                <div className="table-number">
                    <p>Hope you will enjoy your meal<br />Have a lovely day</p>
                </div>
            </div>
            <button className="btn" onClick={generatePDF}>Generate PDF</button>
            <Link className="btn" to={`/${name}/view-table`}>Go to back</Link>
        </>
    );
};

export default Print;
