import React, { useEffect, useState } from "react";
import RestaurantSidebar from "../../component/RestaurantSidebar";
import RestaurantBreadcrumbs from "../../component/RestaurantBreadcrumbs";
import { getParcelBill } from "../../../api/Api";
import axios from "axios";
import "../bills/PrintBill.css";

const Parcel = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const getBill = async () => {
        try {
            const response = await axios.get(getParcelBill, {
                headers: {
                    token: localStorage.getItem("token"),
                },
            });

            if (response.data?.data?.length > 0) {
                setData(response.data.data[0]);
            }
            setLoading(false);
            console.log("Fetched Data:", response.data.data[0]);
        } catch (error) {
            console.error("Error fetching parcel bill:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getBill();
    }, []);

    return (
        <>
            <main id="main" className="main">
                <RestaurantSidebar />
                <RestaurantBreadcrumbs title="Parcel Bill" linkTitle="Back" link="#" />

                {loading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    data ? (
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-md-4 offset-md-2 mb-4">
                                    <div className="border p-3">
                                        <h3>Restaurant Bill</h3>
                                        <h4>Order ID: {data?.id || "N/A"}</h4>
                                        <h5>Customer Name: {data?.name || "N/A"}</h5>
                                        <h6>Contact Number: {data?.contactNumber || "N/A"}</h6>

                                        <table className="table table-bordered table-print">
                                            <thead>
                                                <tr>
                                                    <th>Item</th>
                                                    <th>Quantity</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.parcel_detail?.length > 0 ? (
                                                    data.parcel_detail.map((item, index) => {
                                                        console.log(item);
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.get_menu?.title || "N/A"}</td>
                                                                <td>{item.qty || "N/A"}</td>
                                                                <td>{item.get_menu?.price || "N/A"}</td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="3">No items found</td>
                                                    </tr>
                                                )}

                                            </tbody>

                                        </table>

                                        <h4>
                                            Total:{" "}
                                            {data?.parcel_detail?.reduce(
                                                (total, item) =>
                                                    total + item.qty * parseFloat(item.get_menu?.price || 0),
                                                0
                                            ) || "N/A"}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>No data found</div>
                    )
                )}
            </main>
        </>
    );
};

export default Parcel;
